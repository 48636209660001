<template>
  <a-empty :image-style="{ height: '100px', width: mobile ? '80px' : '' }">
    <div slot="description">
      <span class="font-size-18" style="color: #0000009C">{{heading}}</span>
      <br />
      <span style="color: #7474747a">{{description}}</span>
    </div>
  </a-empty>
</template>

<script>
export default {
  props: ['heading', 'description', 'mobile'],
}
</script>

<style>
</style>
